<template>
  <div>
    <div
      style="padding:10px 0 0 0;margin-right: -15px;
    margin-left: -15px;"
    >
      <div class="btn-list">
        <template>
          <template>
            <div
              class="btn-item-box"
              v-for="(typeName, index) in types"
              :key="index"
            >
              <div :class="theme === 'SAT' ? 'btn-item-type' : 'btn-item-type'">
                <a @click="selectType(typeName)">
                  <label
                    :class="
                      typeName === type
                        ? `btn active ${typeName}`
                        : `btn ${typeName}`
                    "
                  >
                    {{ $t(`sat.${typeName}`) }}
                  </label>
                </a>
              </div>
            </div>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Common from "@/mixins/common.js";
import Theme from "@/common/theme";
import role from "@/mixins/role.js";

export default {
  components: {},

  mixins: [Common, role],

  props: ["type"],
  data() {
    return {
      types: ["Drill Practice", "Partial Tests"]
    };
  },
  computed: {
    isAdmin() {
      let isAdmin = this.isRoleAdmin();
      return isAdmin;
    },
    theme() {
      return Theme.name;
    }
  },
  watch: {},

  mounted() {},

  methods: {
    selectType(type) {
      let name = "SATList";
      if (type === "Partial Tests") {
        name = "SATList";
      }
      if (type === "Full Tests") {
        name = "SATPractices";
      }
      if (type === "Adaptive Tests") {
        name = "SATPractices";
      }
      if (type === "Drill Practice") {
        name = "SATList";
      }
      this.$router.replace({
        name: name,
        query: {
          isNew: this.$route.query.isNew,
          type: type
        }
      });
    }
  }
};
</script>

<style scoped>
.ivy-type-title {
  font-size: 15px;
  font-weight: bold;
  height: 35px;
  line-height: 35px;
  margin: 10px 0;
}
.btn-list {
  display: flex;
  /* justify-content: space-between; */
}
.btn-item-box {
  width: 50%;
  padding: 0 15px;
}
.btn-item {
  width: 200px;
}
.btn-item-type {
  width: 100%;
}
.btn-item-type-ivyway {
  width: 100%;
}
.btn {
  width: 100%;
  height: 80px;
  line-height: 80px;
  cursor: pointer;
  background-color: #f5f5f5;
  color: var(--themeColor);
  text-align: center;
  border-radius: 5px;
  padding: 0 5px;
  margin: 10px 0;
  display: block;
  margin: 10px 0;
  cursor: pointer;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: #edf1f8;
  color: #808080;
  transition: 0.2s 0s;
  box-shadow: 0 5px 35px 0 rgb(83 120 186 / 15%);
  background: url(https://testadmit-sat.s3.ap-northeast-1.amazonaws.com/images/icons/p4.svg)
    no-repeat right bottom #ffffff;
  background-size: 23% 50%;
}
.Drill {
  background: url(https://testadmit-sat.s3.ap-northeast-1.amazonaws.com/images/icons/p6.svg)
    no-repeat right bottom #ffffff;
  background-size: 33% 70%;
}
.Partial {
  background: url(https://testadmit-sat.s3.ap-northeast-1.amazonaws.com/images/icons/p6.svg)
    no-repeat right bottom #ffffff;
  background-size: 33% 70%;
}
.Full {
  background: url(https://testadmit-sat.s3.ap-northeast-1.amazonaws.com/images/icons/p4.svg)
    no-repeat right bottom #ffffff;
  background-size: 33% 70%;
}
.btn-ivyway .btn {
  box-shadow: none;
  background: none;
  background-color: #edf1f8;
}
.btn:hover,
.active {
  color: white;
  background-color: var(--themeColor);
  box-shadow: 1px 3px 6px #aaa;
}
.ivy-type-title {
  display: block;
}
.toefl-sections-xs {
  display: none;
}
.toefl-sections-content-xs .btn-item {
  padding: 0 5px;
}
.toefl-sections-content-xs {
  display: flex;
}
@media screen and (max-width: 992px) {
  .ivy-type-title {
    text-align: center !important;
    height: 25px;
    line-height: 25px;
    margin: 0;
  }
  .d-xs-none {
    display: none;
  }
  .btn-list {
    display: block;
  }
  .btn-item-type,
  .btn-item-box,
  .btn-item {
    width: 100%;
  }
  .btn-item-box {
    padding: 0 15px;
  }
  .ivy-type-title {
    display: none;
  }

  .toefl-sections {
    display: none;
  }

  .toefl-sections-xs {
    display: block;
    width: 100%;
  }

  .toefl-sections-xs > .toefl-sections-header-xs {
    display: block;
    width: 100%;
    height: 35px;
    line-height: 35px;
    background-color: var(--themeColor);
    color: #fff;
    text-align: center;
    border-radius: 5px 5px 0px 0px;
    padding: 0 5px;
    margin-top: 15px;
  }

  .toefl-sections-xs > .toefl-sections-content-xs {
    height: 60px;
    /* border: 1px solid #dcdcdc; */
    border-radius: 0px 0px 5px 5px;
    display: flex;
    padding: 0px 9px;
  }

  .toefl-sections-xs > .toefl-sections-content-xs > .btn-item {
    padding: 0px 6px;
  }
}
</style>
