var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isAdmin || _vm.isTeacher)?[_c('div',{class:`tpos tpos-admin ${_vm.practiceType}`},[_c('div',{staticClass:"l-block"},[_c('div',{staticClass:"ileft"},[_c('b',{staticStyle:{"font-size":"18px"}},[_vm._v(_vm._s(_vm.replace(_vm.title)))]),(_vm.question_count)?_c('div',[_c('span',[_vm._v("Questions: "),_c('b',[_vm._v(_vm._s(_vm.question_count))])])]):_vm._e()])]),_c('div',{staticClass:"admin-r-block"},[(_vm.published)?_c('router-link',{staticClass:"start_test start mr-3",staticStyle:{"background-color":"var(--themeColor)"},attrs:{"to":{
            name: _vm.isPhone ? 'SATTestLockPage' : 'SATTestLockPage',
            params: {
              practiceType:
                _vm.practiceType === 'composePractices' ||
                _vm.practiceType === 'adaptivePractices'
                  ? _vm.practiceType
                  : 'singlePractice',
              id: _vm.id
            }
          }}},[_vm._v(" Start Test ")]):_vm._e(),_c('router-link',{staticClass:"start_test start browse",attrs:{"to":{
            name: _vm.routerLink,
            query: { id: _vm.id }
          }}},[_c('i',{staticClass:"fa fa-eye"}),_vm._v(" Browse ")])],1)])]:[_c('div',{staticClass:"locked tpos"},[_c('div',{staticClass:"l-block"},[_c('b',{staticClass:"ileft",staticStyle:{"font-size":"18px"}},[_vm._v(" "+_vm._s(_vm.replace(_vm.title))+" ")])]),_c('div',{staticClass:"admin-r-block lock"},[(
            _vm.test.user_exams &&
              _vm.test.user_exams.length > 0 &&
              _vm.test.user_exams[0].is_finished === 0
          )?[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.practiceType === 'composePractices' ||
              _vm.practiceType === 'adaptivePractices'
                ? `Resume (starting from Module ${
                    _vm.test.user_exams[0].other_status.pastOrder
                      ? _vm.test.user_exams[0].other_status.pastOrder.sectionOrder
                      : 1
                  } #${
                    _vm.test.user_exams[0].other_status.pastOrder
                      ? _vm.test.user_exams[0].other_status.pastOrder
                          .questionOrder
                      : 1
                  })`
                : `Resume (starting from #${
                    _vm.test.user_exams[0].other_status.pastOrder
                      ? _vm.test.user_exams[0].other_status.pastOrder
                          .questionOrder
                      : 1
                  })`,"placement":"top"}},[_c('router-link',{staticClass:"start_test start",staticStyle:{"background-color":"var(--themeColor)"},attrs:{"to":{
                name: _vm.isPhone ? 'SATMobileTest' : 'SATTest',
                params: {
                  practiceType:
                    _vm.practiceType === 'composePractices' ||
                    _vm.practiceType === 'adaptivePractices'
                      ? _vm.practiceType
                      : 'singlePractice',
                  id: _vm.id
                },
                query: {
                  user_exam_id: _vm.test.user_exams[0].id
                }
              }}},[_c('i',{staticClass:"fa fa-play"}),_vm._v(" "+_vm._s(_vm.$t("courses.Resume"))+" ")])],1)]:_c('router-link',{staticClass:"start_test start",staticStyle:{"background-color":"var(--themeColor)"},attrs:{"to":{
            name: _vm.isPhone ? 'SATTestLockPage' : 'SATTestLockPage',
            params: {
              practiceType:
                _vm.practiceType === 'composePractices' ||
                _vm.practiceType === 'adaptivePractices'
                  ? _vm.practiceType
                  : 'singlePractice',
              id: _vm.id
            }
          }}},[_vm._v(" Start Test ")])],2)])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }